.footer{
    background: #f1f1f1;
    margin-top: 30px;
    padding: 22px 0;
    .footerMenu ul li a,label{
        font-weight:500;
        font-size:15px;
    }
    .footerMenu {
        text-align: left;
    }
    .footerMenu ul {
        text-align: left;
        margin-bottom: 0 !important;
    }
    .footerMenu ul li:after {
        display: none;
    }
    .footerMenu ul li a {
        font-size: 14px;
        padding: 0 !important;
        display: inline-block;
    }
    .footerMenu ul li a:hover {
        color: #eb1881 !important;
    }
    .footerMenu ul{
        margin:0 ;
        padding:0;
        justify-content:center;
        list-style:none
    }
    .footerMenu ul li{
        padding:0;
        position:relative;
        margin-right:12px;
    }
    .footerMenu ul li a{
        line-height:1.2;
        color:#6a6a6a!important;
        cursor:pointer;
        text-decoration:none
    }
    .footerMenu ul li:after{
        content:"|";margin-left:12px
    }
    .footerSocial ul{
        display: flex;
        gap: 0;
        justify-content: start;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .footerMenu ul li:last-child{
        margin-right:0
    }
    .footerMenu ul li:last-child:after{
        display:none
    }

        .footerTitle  {
            img{
                max-width: 160px;
                text-align: left;
            }
            h5{
                font-size: 18px;
                font-weight: 700;
            }
        }
        .footerContent p {
            font-size: 16px;
            line-height: 1.5;
            margin-top: 10px;
        }
        .footerRTA {
            display: inline-flex;
            align-items: center;
            background: #ddd;
            padding: 5px 10px;
            border: solid 1px #035466;
            border-radius: 5px;
            margin-top:15px;
            svg {
                margin-right: 5px;
                width: 35px;
                path {
                    fill: #e91880;
                }
            }
            .RTAText {
                font-size: 10px;
                font-weight: 700;
                padding-left: 0;
            }
        }

}