.importantNot {
    background: #eb1881;
    padding: 7px 0;
    .container {
        display: flex;
        align-items: center;
    }
    .leftSide {
        max-width: 40%;
        flex: 0 0 40%;
        h4 {
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 0;
        }
    }
    .rightSide p{
        color: #fff;
        font-size: 13px;
        line-height: 1.2;
        margin-bottom: 0;
        a {
            font-weight: bold;
            color: #fff;
        }
    }
}
.header {
    position: absolute;
    width: 100%;
    z-index: 1;
    padding:10px 0;
    background: linear-gradient(180deg, black, #09090908);
    color: #FFF !important;
    .asd{
        position: unset;
        background: #f1f1f1;
        a{
            color: #4a4a4a;
        }
        .after-login-tabs li a  {
            color: #4a4a4a;
        }
    }

  
    .headerRight{
        margin-left:auto
    }
}


.headerLogo a img{
    max-width:180px;
    width:100%
}

.hamburger-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    display: none;
}  
.menu-wrap {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.menu li {  
    width: 100%;
}

.menu a {
    display: block;
    padding: 10px;
    color: white;
    text-decoration: none;
    text-align: center;
}

/* Add this to your Header.module.scss or equivalent CSS/SCSS file */

.hamburger-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    display: none;
  }
  
  .close-button {
    display: none;
  }
  
  @media (max-width: 768px) {
    .hamburger-button {
      display: block;
    }
  
    .menu.open .close-button {
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      border: none;
      font-size: 24px;
      cursor: pointer;
    }
  
    .menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .menu-wrap {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  